import { first } from 'lodash-es';
import { NEVER, Observable, Subject, filter, from, map, merge, of, retry, scan, share, shareReplay, startWith, switchMap, tap } from 'rxjs';
import { RealtimeAction } from '../openapi';
import { webSocket } from 'rxjs/webSocket';
import { environment } from '../../environments/environment';
import { UserContextService } from './user-context.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export interface IRealtime<T> {
  action: RealtimeAction;
  data: T;
}

export interface IStateManagerConfig<T, TKey extends string | number | symbol, TCreateArgs = unknown, TUpdateArgs = unknown, TCreateResult = unknown, TUpdateResult = unknown> {
  equals: (a: T, b: T) => boolean,
  getKey: (a: T) => TKey,
  getItem$: (id: TKey) => Observable<T>,
  getList$: () => Observable<T[]>,
  deleteApi: (id: TKey) => Observable<void>,
  createApi: (id: TCreateArgs) => Observable<TCreateResult>,
  updateApi: (id: TUpdateArgs) => Observable<TUpdateResult>,
  userContext?: UserContextService,
  connection?: string,
}

class InnerStateManagerService<T, TKey extends string | number | symbol, TRealtime extends IRealtime<T>, TCreateArgs = unknown, TUpdateArgs = unknown, TCreateResult = unknown, TUpdateResult = unknown> {
  private readonly memoizationItems = {} as Record<TKey, { value$: Observable<T>, updater$: Subject<T> }>;

  constructor(
    private equals: (a: T, b: T) => boolean,
    private getKey: (a: T) => TKey,
    private getItem$: (id: TKey) => Observable<T>,
    private getList$: () => Observable<T[]>,
    private deleteApi: (id: TKey) => Observable<void>,
    private createApi: (id: TCreateArgs) => Observable<TCreateResult>,
    private updateApi: (id: TUpdateArgs) => Observable<TUpdateResult>,
    private userContext?: UserContextService,
    private connection?: string,
  ) {
    this.item$
      .pipe(takeUntilDestroyed())
      .subscribe();
  }

  private readonly item$: Observable<TRealtime> = this.connection && this.userContext
    ? from(this.userContext.getToken())
      .pipe(
        switchMap(token => webSocket<TRealtime>(`${environment.liveEventsUrl}/ws/${this.connection}?access_token=${token}&device=${Date.now()}`)),
        retry({ delay: 2500 }),
        share({ resetOnError: true, resetOnComplete: false }),
      )
    : NEVER;

  readonly refreshList$ = new Subject<void>();
  readonly list$ = of(null).pipe(switchMap(() => merge(
    this.getList$(),
    this.refreshList$.pipe(switchMap(() => this.getList$())),
  ))).pipe(
    shareReplay(1),
    switchMap(list => this.updateWithLiveEvents(list)),
  );

  public get(key: TKey) {
    const cached = this.memoizationItems[key];
    if (cached) {
      return cached;
    }

    const updater$ = new Subject<T>();
    const getItem$ = merge(this.getItem$(key), updater$).pipe(
      switchMap(item => merge(this.updateWithLiveEvents([item]))),
      map(item => first(item)!),
      shareReplay(1),
    );
    return this.memoizationItems[key] = { value$: getItem$, updater$ };
  }

  delete(a: T): Observable<void> {
    const x = this.getKey(a);
    return this.deleteApi(x).pipe(tap(() => this.refreshList$.next()));
  }

  create(item: TCreateArgs): Observable<TCreateResult> {
    return this.createApi(item).pipe(tap(() => this.refreshList$.next()));
  }

  update(item: TUpdateArgs, replaceItem: T): Observable<TUpdateResult> {
    return this.updateApi(item).pipe(tap(() => {
      this.get(this.getKey(replaceItem)).updater$.next(replaceItem);
      this.refreshList$.next();
    }));
  }

  dispose(): void {
    this.refreshList$.complete();
  }

  private updateItems(list: T[], aggEvents: TRealtime[]) {
    return list.map(item => {
      return this.combineChanges(aggEvents, item);
    }).filter((item: T | null): item is T => !!item);
  }

  private combineChanges(liveEvents: TRealtime[], listItem: T): T | null {
    for (const liveEvent of liveEvents) {
      if (!this.equals(liveEvent.data, listItem)) {
        continue;
      }

      switch (liveEvent.action) {
        case RealtimeAction.Deleted:
          return null!;
        case RealtimeAction.Modified:
          listItem = listItem
            ? { ...listItem, ...liveEvent.data }
            : liveEvent.data
          break;
        default:
          throw new Error($localize`Action not supported.`);
      }
    }
    return listItem;
  }

  private updateWithLiveEvents(list: T[]) {
    return this.item$.pipe(
      filter(i => this.handleAdded(i)),
      scan<TRealtime, TRealtime[]>((accumulator, val) => ([...accumulator, val]), [] as TRealtime[]),
      startWith<TRealtime[]>([]),
      map(realtimeEvents => this.updateItems(list, realtimeEvents)),
    );
  }

  private handleAdded<TRealtime extends IRealtime<T>>(i: TRealtime): boolean {
    if (i.action === RealtimeAction.Added) {
      this.refreshList$.next();
      return false;
    } else {
      return true;
    }
  }
}

export class StateManagerService<T, TKey extends string | number | symbol, TRealtime extends IRealtime<T>, TCreateArgs = unknown, TUpdateArgs = unknown, TCreateResult = unknown, TUpdateResult = unknown> extends InnerStateManagerService<T, TKey, TRealtime, TCreateArgs, TUpdateArgs, TCreateResult, TUpdateResult> {
  constructor(config: IStateManagerConfig<T, TKey, TCreateArgs, TUpdateArgs, TCreateResult, TUpdateResult>) {
    super(
      config.equals,
      config.getKey,
      config.getItem$,
      config.getList$,
      config.deleteApi,
      config.createApi,
      config.updateApi,
      config.userContext,
      config.connection,
    )
  }
}
